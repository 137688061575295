import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import useSWR from "swr"
import cfg from "../../config"
import { User } from "../../const/users"
import Search from "../dumps/Search"
import { useCallback, useState } from "react"
import * as api from "../../api/user-dealer"
import { Dealer } from "../../const/dealer"
import { DealerUser } from "../../const/dealer-user"
import MyPagination from "../dumps/MyPagination"

type Props = {
  open: boolean
  dealer: Dealer
  onClose: () => void
}

const tableHeaders = ['Name', 'Email', 'Actions'];

function UsersDialog({ open, dealer, onClose }: Props) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const { data: usersData = { data: [], meta: { totalPages: 0 }}, isLoading } = useSWR(`${cfg.profile}/api/v2/users?page=${page}&limit=${limit}&search=${search}`, {
    revalidateOnFocus: false,
    keepPreviousData: true,
  });
  const { data: dealerUsers = [], mutate } = useSWR(`/api/v1/dealer/${dealer.id}/users`);

  const handleSearch = useCallback((v: string) => {
    setSearch(v);
    setPage(1);
  }, []);

  const handleAddUser = useCallback(async (userId: string) => {
    if (dealer.id) {
      const res = await api.add({ dealerId: dealer.id, userId });
      mutate(() => [...dealerUsers, res]);
    }
  }, [dealer, mutate, dealerUsers]);

  const handleRemoveUser = useCallback(async (userId: string) => {
    if (dealer.id) {
      const res = await api.remove({ dealerId: dealer.id, userId });
      mutate(() => dealerUsers.filter((du: DealerUser) => du.userId !== res.userId));
    }
  }, [dealer, mutate, dealerUsers]);

  return open && (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>{`${dealer.name} Users`}</DialogTitle>
      <DialogContent>
        <Box mt={1} mb={2}>
          <Search isLowerCase onSearch={handleSearch} delay={500}/>
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  {tableHeaders.map((th) => (
                    <TableCell key={th}>{th}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%' }} />}
                {usersData.data.map((user: User) => (
                  <TableRow key={user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {dealerUsers.find((du: DealerUser) => du.userId === user.id)
                        ? <Button onClick={() => handleRemoveUser(user.id)} size="small" color="error">Remove</Button>
                        : <Button onClick={() => handleAddUser(user.id)} size="small" color="success">Add</Button>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box mt={2} display={'flex'} justifyContent={'center'}>
          <MyPagination
            initialPage={page}
            initialLimit={limit}
            total={usersData.meta.totalPages * limit}
            onPageChange={setPage}
            onLimitChange={setLimit}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default UsersDialog;
